.icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 20px;
}

.icon-list {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow: hidden;
    width: 700px;
    position: relative;
}

.icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: transform 0.3s, opacity 0.3s, width 0.3s, height 0.3s;
    opacity: 0.5;
    cursor: pointer;
}

.icon-item.center {
    opacity: 1;
}

.icon-image {
    width: 200px;
    height: 200px;
    transition: transform 0.3s, width 0.3s, height 0.3s;
}

.icon-image.center {
    width: 260px;
    height: 260px;
    margin-top: 200px;
}

.icon-country-name {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    opacity: 0.7;
    transition: font-size 0.3s, opacity 0.3s;
}

.icon-country-name.center {
    font-size: 20px;
    opacity: 1;
}

.icon-scroll-btn {
    cursor: pointer;
}

.l {
    position: absolute;
    left: 0;
}

.r {
    position: absolute;
    right: 0;
}

.btn_link_country {
    padding: 15px 30px;
    background-color: #0C6316;
    color: #fff;
    border: 2px white solid;
    border-radius: 35px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 25px;
    min-width: 177px;
}

@media (max-width: 768px) {
    .l {
        left: 0;
    }
    
    .r {
        right: 0;
    }
    .icon-container{
        margin-top: 10px;
    }
    .icon-scroll-btn {
        display: none; 
    }

    .icon-list {
        overflow-x: scroll;
        display: flex;
        scroll-snap-type: x mandatory;
    }

    .icon-item {
        scroll-snap-align: center;
        flex: 0 0 auto;
    }
}

