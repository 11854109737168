.social-icons-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons {
    display: flex;
    overflow: hidden;
}

.icon-img {
    object-fit: contain;
    margin: 0 29px; 
    width: 64px;

}

.scroll-btn {
    cursor: pointer;
}
@media (max-width: 768px) {
  
    .icon-img{
        width: 30px;
    }
}