.description_header {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2060E9;
    font-size: 30px;
    font-weight: bold;
    margin-top: 50px;
}

.description_header h1 {
    color: #fff;
    font-weight: bold;
    margin-right: 15px;
}

.description_content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    margin-top: 40px;
}

.content_item {
    background-color: #7DC4FF;
    padding: 10px;
    height: 76px;
    color: black;
    font-size: 18px;
    font-weight: bold;
    width: 60%;
    border-radius: 0 25px 25px 0;
    text-align: left;
    align-self: flex-start;
}

.content_item1 {
    background-color: #7DC4FF;
    padding: 10px;
    height: 76px;
    color: black;
    font-size: 18px;
    font-weight: bold;
    width: 60%;
    border-radius: 25px 0 0 25px;
    align-self: flex-end;
}

.description_content strong {
    color: #2060E9;
    font-size: 25px;
}

@media (max-width: 768px) {
    .content_item,
    .content_item1 {
        width: 90%;
    }
}