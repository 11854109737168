.service-item {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
}

.service-columns {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
}

.column {
    width: 50%;
}

.status-column {
    width: 30%;
}

.button-column {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.service-el {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.service-el strong {
    color: #2060E9;
    font-size: 18px;
}

.vpn-details strong {
    color: #2060E9;
    font-size: 18px;
}

.vpn-details span {
    background-color: #212139;
    font-size: 12px;
    font-weight: bold;
    padding: 5px 50px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    gap: 50px;
}

.service-el span {
    background-color: #212139;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 50px;
    border-radius: 25px;
}

.service-el span.amount {
    color: #30DE4A;
}

.service-el span.start-date {
    color: #30DE4A;
}

.service-el span.finish-date {
    color: #FF0000;
}

.status.active {
    color: #30DE4A;
}

.status.inactive {
    color: #f44336;
}

.continue-tariff-btn {
    background-color: #4caf50;
    color: #fff;
    border: none;
    padding: 10px 17px;
    border-radius: 5px;
    cursor: pointer;
}

.continue-tariff-btn:hover {
    background-color: #45a049;
}

.vpn-details {
    margin-top: 20px;
    border-radius: 10px;
    color: #30DE4A;
    font-size: 14px;
    word-break: break-word;
    text-align: center;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.show-more-container {
    text-align: center;
    margin-top: 20px;
}

.show-more-btn {
    background-color: #00aaff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
}

.show-more-btn:hover {
    background-color: #008fcc;
}

.filter_service {
    background: white;
    margin: 30px 0;
    border-radius: 20px;
    padding: 10px;
}


.profile-container {
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;
}

.title_h1 {
    text-align: center;
    margin: 20px 0;
    font-size: 36px;
    color: #fff;
    font-weight: bold;
}

.profile-container section {
    margin: 50px auto;
}

.profile-container h2 {
    font-size: 24px;
    color: #007bff;
    text-align: center;
    font-weight: bold;
}

.profile-container p,
.profile-container li {
    font-size: 18px;
    line-height: 1.6;
}


.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 800px;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.qr-code img {
    max-width: 100%;
    height: auto;
}

.pricing-options {
    margin-top: 20px;
    height: 640px;
}

.pricing-cards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-around;
}

.pricing-card .description {
    margin-top: 10px;
    color: #555;
    /* Можна додати стилі для опису */
}

.show_more_btn {
    color: #007bff;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
}

.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    text-align: center;
    position: relative;
}

.close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    color: #888;
    cursor: pointer;
}

h2 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #333;
}

.access-code {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 4px;
    font-size: 1.1rem;
    margin: 10px 0;
}

.fs10 {
    font-size: 12px !important;
}




.personal-info {
    position: relative;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
}

.personal-info h2 {
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
}

.personal-info .edit {
    cursor: pointer;
    font-size: 16px;
    text-decoration: underline;
    text-decoration: none;
}

.personal-info input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f9f9f9;
}

.personal-info input:focus {
    border-color: #007bff;
    background-color: #fff;
    outline: none;
}

.edit-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.edit-buttons button {
    padding: 10px 15px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.edit-buttons .save {
    background-color: #28a745;
    color: #fff;
}

.edit-buttons .save:hover {
    background-color: #218838;
}

.edit-buttons .cancel {
    background-color: #dc3545;
    color: #fff;
}

.edit-buttons .cancel:hover {
    background-color: #c82333;
}


.transaction-history li .qr-code {
    position: absolute;
    top: 0;
    right: 10px;
}

.transaction-history li .qr-code img {
    max-height: 150px;
}

.transaction-columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.column_transaction {
    width: 55%;
}

.transaction-el strong {
    font-size: 20px;
}

.transaction-el span {
    font-size: 16px;
    font-weight: bold;
}

.transaction-el {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.qr-code-column {
    width: 30%;
}

.transaction-item {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
}

.service-history li {
    position: relative;
}

.service-history li {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 10px;
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 120px;
    text-align: center;

}


.personalInfoContent strong {
    color: #2060E9;
    font-size: 18px;
}

.personalInfoItem {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
    width: 60%;
    margin: 20px;
}

.personalInfoEl {
    background-color: #212139;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 0px 40px;
    border-radius: 25px;
    margin-right: 10px;
}

.refillBalance {
    color: #30DE4A;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 10px;
}

.item_balance {
    color: #30DE4A;
    background-color: #212139;
    font-size: 18px;
    font-weight: bold;
    padding: 0px 40px;
    border-radius: 25px;

}

.personalInfoContent {
    width: 60%;
}

.personalInfoItemBalance {
    display: flex;
    align-items: center;
    gap: 25px;
    justify-content: space-between;
    width: 37%;
    margin: 20px;
}

.modal_top_up_balance {

    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 30%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.payment-buttons button {
    padding: 15px 30px;
    background-color: #4caf50;
    color: #fff;
    border: 2px white solid;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 25px;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
}

.close-button:hover {
    color: #f44336;

}

.selectMonths {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    font-size: 16px;
    border: 0;
    background-color: #212139;
}

.confirm-btn {
    padding: 15px 30px;
    background-color: #4caf50;
    color: #fff;
    border: 2px white solid;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin: 25px auto;
}

.copy-message {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    pointer-events: none;
    transform: translate(-50%, -50%);
    z-index: 1000;
    animation: fadeInOut 3s;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }

    10% {
        opacity: 1;
    }

    90% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.filter_service {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.selectFilter {
    display: flex;
    align-items: center;
    gap: 10px;
}

.selectFilter select {
    background-color: #212139;
    padding: 12px 25px;
    border-radius: 25px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
}

.selectFilter img {
    cursor: pointer;
}

.block {
    display: flex;
    width: 100%;
    padding: 0px !important;
    text-align: left;
    justify-content: space-between;
}

.modalContinion {
    background: #fff;
    width: 40%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 20px;
    position: fixed;
    top: 30%;
    left: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modalContinion input {
    width: 80%;
    padding: 10px;
    font-size: 16px;
    border: 0;
    background-color: #212139;
    margin: 0 auto;
    color: #fff;
    font-weight: bold;
    border-radius: 15px;
}

.btnOK {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: 2px white solid;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 25px;
}

.btnCancel {
    background-color: #2060e9;

}

.info {
    display: flex;
    justify-content: right;
    margin: 15px 0;
}

.info img {
    width: 25px;
    cursor: pointer;
}

.closeInfoTariff {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    justify-content: right;
}

.infoTariff {
    background: #fff;
    width: 40%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    padding: 20px;
    position: fixed;
    top: 5%;
    left: 30%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 700px;
}

.historyList {
    width: 90%;
    margin: 0 auto;
    max-height: 630px;
    overflow: auto;
}

.historyItem {
    margin: 20px 0;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.historyEl {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
}

.historyEl strong {
    font-size: 16px;
    font-weight: bold;
    color: #2060e9;
}

.historyEl div {
    background-color: #212139;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 5px 50px;
    border-radius: 25px;
}

.location-select {
    background-color: #212139;
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    padding: 8px 50px;
    border-radius: 25px;
}

.loc {
    margin-left: 10px;
    padding: 5px 40px !important;
}

.personalInfoConteiner {
    display: flex;
}

.transactionIDImg {
    cursor: pointer;
    margin-left: 10px;
}

.selectedLocationBlock {
    width: 74%;
}
.errorCheck{
    text-align: left; 
    font-weight: 600;
    padding: 10px;
}
@media (max-width: 768px) {
    .profile-container {
        padding: 10px 0;
    }

    .profile-container section {
        margin: 10px;
    }

    .personal-info {
        padding: 10px 0;
    }

    .personal-info h2 {
        margin: 0;
    }

    .personalInfoConteiner {
        display: block;
    }

    .personalInfoContent {
        width: 100%;
    }

    .personalInfoItem {
        width: 100%;
        margin: 15px 0;
        padding: 0 5px;
    }

    .personalInfoItemBalance {
        width: 100%;
        margin: 20px 0;
        padding: 0 5px;
    }

    .item_balance {
        padding: 0 30px;
    }

    .filter_service {
        display: flex;
        flex-wrap: wrap;
    }

    .selectFilter {
        width: 100%;
        margin: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .filter_service img {
        width: 30px;
    }

    .selectFilterImg {
        display: flex;
        justify-content: end;
    }

    .service-columns {
        flex-wrap: wrap;
    }

    .column {
        width: 100%;
    }

    .vpn-details {
        flex-wrap: wrap;
    }

    .vpn-details span {
        padding: 5px;
        gap: 5px;
        margin-top: 5px;
    }

    .selectFilter select {
        padding: 5px 15px;
    }

    .transaction-filter_service {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .filter_service {
        justify-content: end;
    }

    .transaction-item {
        padding: 10px;
    }

    .column_transaction {
        width: 100%;
    }

    .transactionIDImg {
        margin: 5px;

    }

    .transactionID {
        width: 85%;
        align-items: center;
    }

    .modal-content {
        display: flex;
        flex-wrap: wrap;
        
    }

    .modal {
        width: 100%;
    }

    .selectMonths {
        padding: 0;
    }

    .service-el span {
        padding: 5px 20px;
    }

    .service-item {
        width: 100%;
        padding: 10px;
    }

    .selectedLocationBlock {
        width: 100%;
    }
    .errorCheck{
        text-align: center;
    }
    .modalContinion{
        width: 92%;
        left: 15px;
    }
    .infoTariff{
        width: 92%;
        left: 4%;
    }
    .historyList{
        width: 100%;
    }
    .infoTariff{
        padding: 10px;
    }
    .modal_top_up_balance{
        width: 92%;
    }
    .edit-buttons{
        padding: 4px;
    }
    .modal-content h2{
        margin: 10px auto;
    }
 
}