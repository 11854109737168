.footerContent {
    display: flex;
    justify-content: space-around;
    padding: 17px;

}

.footerItem {
    text-align: left;
    font-weight: bold;
}

.footerItem p {
    font-size: 16px;
    margin-top: 15px;
}

.footerItem div {
    margin-top: 15px;
}

.footerItem img {
    margin: 10px;
    width: 34px;
}

.footer-copyright {
    background-color: white;
    font-size: 20px;
    font-weight: bold;
    color: black;
}

.footerItem a {
    color: #fff;
}

@media (max-width: 768px) {
    .footerContent {
        display: flex;
        justify-content: space-around;
        padding: 5px;
        flex-wrap: wrap;
    }

    .footerItem {
        width: 48%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footerItem p {
        margin-top: 7px;
    }
    .footerItem h3 {
        text-align: center;
    }

    .footerItem img {
        width: 30px;
    }

}