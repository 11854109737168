.setup {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px;
}

.num {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid #2394bc;
    color: #2394bc;
    font-size: 35px;
    font-weight: bold;
}

.setup1 {
    display: flex;
    justify-content: space-between;
    width: 52%;
}

.settings {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
}

.setup p {
    color: #fff;
    font-weight: bold;
    font-size: 18px;
    width: 85%;
}

.block1 {
    display: flex;
    justify-content: space-between;
}

.setup3 {
    width: 52%;
    display: flex;
    position: absolute;
  bottom: -70px;
}

.setupInfo {
    margin-left: 20px;
    position: relative;
    width: 70%;
}

.img_arrow1 {
    position: absolute;
    top: 49%;
    left: 29%;
}

.setup2 {
    width: 39%;
    display: flex;
}

.img_arrow2 {
    position: absolute;
    top: 24%;
    right: -51px;
}

.img3 {
    position: absolute;
    top: 33%;
    left: -165px;
}

.img_arrow3 {
    position: absolute;
    top: 50%;
    left: -200px;
}

.setup4 {
    margin-top: 120px;
    width: 100%;
    position: relative;
}

.setup4_block {
    display: flex;
    align-items: center;
}

.setup4_block p {
    margin-left: 10px;
    width: 32%;
}

.img_arrow {
    position: absolute;
    top: 44%;
    left: 200px;
}

.img_setup {
    margin-top: 50px;
}

@media (max-width: 768px) {

    .setup1,
    .setup2,
    .setup3 {
        width: 100%;
        margin: 10px 0;
        padding: 0 10px;
        position: inherit;
    }

    .num {
        width: 40px;
        height: 40px;
    }

    .img_arrow,
    .img_arrow1,
    .img_arrow2,
    .img_arrow3 {
        width: 40px;
    }

    .img_arrow1 {
        left: 70%;
    }

    .img_arrow {
        top: 62%;
        left: 68px;
    }

    .img_arrow3 {
        top: 45%;
        left: -14px;
    }

    .img_arrow2 {
        top: 19%;
        right: -64px;
    }

    .setup2 p {
        width: 100%;
    }

    .setupInfo {
        margin-left: 13px;
    }

    .img_setup2 {
        width: 200px;
        margin-left: 90px;
    }

    .img3 {
        width: 150px;
        left: -90px;
        margin-left: 90px;
    }
    .setup4{
        padding: 0 10px;
    }
    .setup4_block p {
        width: 85%;
    }
}