.user {
    font-size: 20px;
    background-color: steelblue;
    width: 30%;
    color: white;
    cursor: pointer;
    padding: 10px;
}

body {
    font-family: 'Roboto', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #212139;
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.header_image {
    margin: 0 auto;
    width: 300px;
}

.social-icons {
    margin: 20px 0;
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.banner h1 {
    font-size: 52px;
    font-weight: bold;
    margin: 0;
    color: #ffffff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.banner p {
    font-size: 52px;
    font-weight: bold;
    margin: 0;
    color: #ffffff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
}

.banner p {
    font-size: 20px;
    margin: 20px 0;
}

.banner button {
    padding: 15px 30px;
    background-color: #3B6FF1;
    color: #fff;
    border: 2px white solid;
    border-radius: 20px;
    cursor: pointer;
    font-size: 20px;
    transition: background-color 0.3s, transform 0.3s;
    margin-top: 25px;
}

.banner button:hover {
    background-color: #1656f7;
    transform: translateY(-5px);
}

.how-it-works {
    padding: 70px 0;
    background-color: #f4f4f9;
    text-align: center;
}

.steps {
    display: flex;
    justify-content: space-around;
}

.step {
    width: 30%;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.step img {
    width: 80px;
    margin-bottom: 20px;
}

.step:hover {
    transform: scale(1.05);
}

.pricing {
    padding: 70px 5px;
    text-align: center;
}

.pricing h2 {
    font-size: 42px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #333;
}

.pricing-options {
    display: flex;
    justify-content: space-between;
}


.pricing-features {
    background: white;
    color: #555;
    font-size: 14px;
    min-height: 50%;
}


.pricing-card {

    width: 24%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    text-align: center;
    font-family: 'Arial', sans-serif;
    color: white;
    position: relative;
}



.pricing-btn {
    display: block;
    margin: 20px auto;
    padding: 5px 30px;
    font-size: 16px;
    color: white;
    background-color: #ff77a9;
    border: none;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: -35px;
    left: 26%;

}

.pricing-btn:hover {
    background-color: #d0008a;
    /* Колір кнопки при наведенні */
}

.pricing-features div {

    font-size: 17px;
    font-weight: 600;

}

.pricing-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.pricing-card h3 {
    font-size: 23px;
    margin-bottom: 0;
    color: #fff;
    padding: 15px 0;
    font-weight: bold;
}

.pricing-card p {
    font-size: 18px;
    font-weight: bold;

}

.features {
    padding: 70px 0;
    text-align: center;
}

.features h1 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 40px;
    color: #fff;
}

.features p {
    font-size: 12px;
    font-weight: bold;
    color: #fff;
}


.security-animation {
    padding: 70px 0;
    background-color: #fff;
    text-align: center;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.02);
    }

    100% {
        transform: scale(1);
    }
}

.animation-wrapper {
    margin-top: 20px;
}

.info_tariff {
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 10px;
    text-align: left;
}

.footer {
    background-color: #2060E9;
    color: #fff;
    text-align: center;
    font-size: 18px;
}

.footer-links {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.footer-links li {
    margin: 0 15px;
}

.footer-links li a {
    color: #ff6f61;
    text-decoration: none;
}

.footer-links li a:hover {
    text-decoration: underline;
}


.pricing-header {
    background: linear-gradient(to bottom, #da77f2, #ff77a9);
    padding: 20px 10px;
    color: white;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}





.pricing-card-family p {
    font-size: 18px;
    font-weight: bold;
}

.pricing-header-family {
    background: linear-gradient(180deg, #a58eff, #e3c2ff);
    padding: 20px 10px;
    color: white;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pricing-price-family {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    color: white;
}

.pricing-per-month-family {
    font-size: 14px;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.8);
}

.pricing-title-family {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.btn_family {
    background-color: #c380fd;
    left: 26%;
}

.btn_family:hover {
    background-color: #7b5df3;
}

.pricing-card-family h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #fff;
    padding: 15px 0;
    font-weight: bold;
}

.pricing-card-premium p {
    font-size: 18px;
    font-weight: bold;
}

.pricing-header-premium {
    background: linear-gradient(180deg, #32a852, #74db8c);
    padding: 20px 10px;
    color: white;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.pricing-price-premium {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    color: white;
}

.pricing-per-month-premium {
    font-size: 14px;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.8);
}

.pricing-title-premium {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

.btn_premium {
    background-color: #74db8c;
    left: 26%;
}

.btn_premium:hover {
    background-color: #32a852;
}

.pricing-card-premium h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: #fff;
    padding: 15px 0;
    font-weight: bold;
}





.dedicated {
    background: linear-gradient(180deg, #5e17eb, #31087b);
    display: flex;
    flex-direction: column;
    justify-content: inherit;
}

.pricing-header-dedicated {
    color: white;
    height: 150px;
    margin: 0 auto;

}

.pricing-price-dedicated {
    font-size: 36px;
    font-weight: bold;
    margin: 0;
    color: white;
}

.pricing-per-month-dedicated {
    font-size: 14px;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.8);
}

.pricing-title-dedicated {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* .contact-link-dedicated {
    display: block;
    margin: 20px auto;
    padding: 5px 30px;
    font-size: 16px;
    color: white;
    background-color: #74db8c;
    border: none;
    border-radius: 25px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: absolute;
    bottom: 33px;
    left: 68%;
}

.contact-link-dedicated:hover {
    background-color: #32a852;
} */

.pricing-card-dedicated h3 {
    font-size: 28px;
    margin-bottom: 10px;
    color: black;
    padding: 15px 0;
    font-weight: bold;
}

.pricing-servers-dedicated {
    color: black;
    font-size: 18px;
    font-weight: bold;
}

.info_tariff img {
    margin-right: 10px;
}

.custom-hr {
    border: none;
    height: 1px;
    background-color: #2060E9;
    width: 60%;
    margin: 0 auto;
}

.pricing-header-dedicated img {
    margin: 0 auto;
    width: 100px;
}

@media (max-width: 768px) {
    body {
        font-size: 14px;
    }

    .container {
        padding: 0 15px;
    }

    .header_image {
        width: 50px;
    }

    .banner button {
        padding: 10px 20px;
        font-size: 16px;
        margin-top: 15px;
    }


    .pricing-options {
        flex-direction: column;
        gap: 20px;
    }

    .pricing-card {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .pricing {
        padding: 20px 0;
    }

    .pricing-header,
    .pricing-header-family,
    .pricing-header-premium,
    .pricing-header-dedicated {
        width: 120px;
        height: 120px;
    }



    .pricing-price-family,
    .pricing-price-premium,
    .pricing-price-dedicated {
        font-size: 28px;
    }

    .pricing-title-family,
    .pricing-title-premium,
    .pricing-title-dedicated {
        font-size: 16px;
    }

    .pricing-btn {
        padding: 10px 20px;
        font-size: 14px;
        bottom: -20px;
        left: 20%;
    }

    .features h1 {
        font-size: 24px;
    }

    .features p {
        font-size: 10px;
    }

    .footer {
        font-size: 14px;
    }

    .footer-links {
        flex-direction: column;
        gap: 10px;
    }

    .footer-links li {
        margin: 0;
    }

    .info_tariff {
        flex-direction: column;
        align-items: flex-start;
    }

    .info_tariff img {
        margin-bottom: 10px;
        width: 20px;
    }

    .pricing-options {
        height: auto;
        width: 90%;
        margin: 0 auto;

    }

    .pricing-cards {
        margin-bottom: 50px;
    }

    .pricing-btn {
        bottom: -37px;
        left: calc(50% - 40px);
    }
    .pricing-features{
        padding-bottom: 40px;
    }
}