.registration_conteiner {
    width: 50%;
    margin: 20px auto;
}

.registration_form {
    width: 80%;
    margin: auto;
}

.btn_noAccount {
    margin-top: 16px;
    text-align: center;
}

.forgotPassword {
    margin-top: 16px;
    text-align: center;
}

@media (max-width: 768px) {
    .registration_conteiner {
        width: 90%;
    }

    .registration_form {
        width: 100%;
    }

    .btn_noAccount,
    .forgotPassword {
        margin-top: 5px;
    }
}