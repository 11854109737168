.termsOfUse h1{
    margin: 20px 0;
    font-size: 35px;
}
.termsOfUse h2{
    margin: 20px 0;
    color: #fff;
}
.termsOfUse p{
    margin: 10px 0;
}
.termsOfUse h4{
    margin: 20px 0;
    font-size: 20px;
}
.termsOfUse h5{
    margin: 20px 0;
    font-size: 20px;
}
.termsOfUse p{
    margin: 10px 0;
    font-size: 15px;
}
.termsOfUse li{
    margin: 5px 0;
}
.link_chat{
    color: #2060e9;
}