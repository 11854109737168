.advantages {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 60px auto;
    color: white;
    max-height: 500px;
    padding: 10px;
}

.advantagesTitle {
    text-align: center;
    margin-top: 60px;
    font-size: 30px;
    font-weight: bold;
    color: white;
}

.item_advantages {
    text-align: center;
    width: 295px;
}

.item_advantages img {
    display: block;
    margin: 0 auto;
}

.item_advantages h2 {
    margin-top: 10px;
    color: #fff;
    font-weight: bold;
    font-size: 25px;

}

.advantages h4 {
    margin-top: 70px;
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    text-align: left;
}

.underHeading {
    height: 70px;
}

.item_speed {
    width: 273px;
}

.item_setting {
    width: 242px;
}

.refund h4 {
    width: 300px;
}

@media (max-width: 768px) {
    .advantagesTitle {
        margin-top: 25px;
    }

    .advantages {
        margin: 30px auto;
        display: flex;
        flex-wrap: wrap;
    }

    .item_advantages img {
        width: 50px;
    }

    .item_advantages h4 {
        margin-top: 5px;
        text-align: center;
    }

    .item_advantages {
        width: 25%;
        min-width: 150px;
        margin: 10px 5px;
       
    }


}