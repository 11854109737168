@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src: url("/public/fonts/montserrat/montserrat-v25-latin-ext_latin_cyrillic-regular.woff") format("opentype");
}

@font-face {
  font-family: Montserrat-Black;
  src: url("/public/fonts/montserrat/montserrat-v25-latin-ext_latin_cyrillic-900.woff") format("opentype");
}

@font-face {
  font-family: Montserrat-BlackItalic;
  src: url("/public/fonts/montserrat/montserrat-v25-latin-ext_latin_cyrillic-900italic.woff") format("opentype");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("/public/fonts/montserrat/montserrat-v25-latin-ext_latin_cyrillic-600.woff") format("opentype");
}

@font-face {
  font-family: Montserrat-BoldItalic;
  src: url("/public/fonts/montserrat/montserrat-v25-latin-ext_latin_cyrillic-600italic.woff") format("opentype");
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .tw-no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .tw-no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }

    .tw-custom-scrollbar {
      scrollbar-color: grey, transparent;
      scrollbar-width: 6px;
    }

    .tw-custom-scrollbar::-webkit-scrollbar {
      width: 6px;
    }

    .tw-custom-scrollbar::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
    
    .tw-custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: grey;
      border-radius: 15px;
      border: 3px;
    }
    .tw-text-2xl{
      font-size: 30px!important;
    }
}
.isModal{
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  text-align: center;
  position: fixed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  top: 20%;
  left: 28%;
}
.isModal h2{
  font-weight: bold;
}
.isModal input{
  border: 3px solid rgb(32, 96, 233);
  border-radius: 25px;
  width: 70%;
  padding: 10px;
}

.isModal button{
  padding: 15px 30px;
  background-color: #3B6FF1;
  color: #fff;
  border: 2px white solid;
  border-radius: 20px;
  cursor: pointer;
  font-size: 20px;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 20px;
}
.closeIsModal{
  cursor: pointer;
  display: flex;
  justify-content: right;
  font-size: 18px;
  font-weight: bold;
}