body * {
    font-size: 12px;
}
header{
    background-color: #333;
}
.h1Title {
    font-size: 30px;
    padding: 10px 20px;
    color: #fff;
    margin-bottom: 0;
    text-transform: capitalize;
}

.h2Title {
    font-size: 24px;
    padding: 10px 20px;
    margin-bottom: 0;
}

body h1 {
    font-size: 30px;
}

body h2 {
    font-size: 24px;
}

body h3 {
    font-size: 20px;
}

body h4 {
    font-size: 18px;
}

body h5 {
    font-size: 16px;
}

body h6 {
    font-size: 14px;
}

.content_wrap {
    min-height: 600px;
}

.singleForm>* {
    font-size: 12px;
}

@media screen and (max-width: 1400px) {
    body * {
        font-size: 10px !important;
    }
}

.sun-editor h1 {
    background: #fff;

}

.noData {
    font-size: 12px;
    text-align: center;
}

.MuiSvgIcon-root,
.MuiButtonBase-root svg {
    font-size: 22px;
}
.react-datepicker-ignore-onclickoutside,
.react-datepicker__input-container input{
    height: 27px;
}
.spinner {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(238, 237, 237, 0.8);
    width: 100%;
    height: 100%;
    z-index: 1000;
}

.spinner:after {
    animation: changeContent .8s linear infinite;
    display: block;
    content: "⠋";
    font-size: 20px;
}

@keyframes changeContent {
    10% {
        content: "⠙";
    }

    20% {
        content: "⠹";
    }

    30% {
        content: "⠸";
    }

    40% {
        content: "⠼";
    }

    50% {
        content: "⠴";
    }

    60% {
        content: "⠦";
    }

    70% {
        content: "⠧";
    }

    80% {
        content: "⠇";
    }

    90% {
        content: "⠏";
    }
}




a {
    color: #424242;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

#logo {
    margin: 0 5px;
    background: url(/public/images/logo.webp) no-repeat 0 0;
    background-size: contain;
    width: 300px;
    height: 35px;
}

#logo img {
    display: block;
}

.sorted.active {
    position: relative;
}

.sorted.active.asc::after {
    content: '';
    position: absolute;
    top: 7px;
    right: calc(50% - 7px);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-bottom: 7px solid green;
    border-right: 7px solid transparent;
    cursor: pointer;
    color: #fff;
}

.sorted legend span {
    border: #000000;
    color: #000;
}

.sorted.active.desc::after {
    content: '';
    position: absolute;
    top: 0;
    right: calc(50% - 7px);
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid red;
    cursor: pointer;
    color: #fff;
}

tr:nth-of-type(2n) {
    background: rgb(238, 237, 237);
}

table:not(.tableWAuto) {
    width: 99% !important;
    margin: 0 auto;
}
td,
th {
    border-width: 1px !important;
    padding: 4px !important;
}

th {
    background: white;
    box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
    vertical-align: baseline;
    z-index: 8;

}

thead {
    position: sticky;
    top: 0;
    z-index: 8;
}

.MuiSelect-select {
    text-align: left;
}


tr.active {
    background-color: #e3eefa;
}

.error input,
input.error,
.error select,
select.error,
.error .MuiTextField-root,
.error .MuiFormControl-root,
.error .MuiSelect-select {
    border: 1px solid red !important;
}

.hiddenItem {
    display: none !important;

}

footer {
    background-color: #333;
    height: 200px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
    overflow: 'hidden';
}


.arrowBG {
    background: url(/public/images/arrow.svg) no-repeat 0 0;
    background-size: 6px;
    background-position: center;
}

.arrowRight {
    background: url(/public/images/arrowRight.svg) no-repeat 0 0;
    background-size: 7px;
    background-position: center;
    width: 10px;
    height: 15px;
    margin-right: 15px;
}

.dbWarning {
    width: 200px;
    color: #fff;
    padding: 8px 0;
    text-align: center;
}

.dbWarning.work {
    background: #ff6b6b;
}

.dbWarning.test {
    background: #217b42;
}

.MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-page {
    padding-top: 4px !important;
}

.onlineList {
    display: flex;
    background: #333;
    padding: 15px 0 5px 0;
    justify-content: flex-end;
}

.onlineList .onlineItem {
    cursor: default;
    display: flex;
    overflow: hidden;
    max-width: 200px;
}

.onlineList .onlineItem._hidden {
    max-width: 0;
    transition: all 0.5s ease;
}

.onlineList .onlineItem:hover {
    opacity: 1 !important;
}

.onlineList .onlineItem .user_icon span {
    color: #fff;
    line-height: 30px;
    text-transform: uppercase;
}

.onlineList .onlineItem .user_info {
    margin-right: 15px;
    text-align: left;
    display: flex;
    align-items: center;
}

.onlineList .onlineItem .user_icon {
    border: 1px solid #d2d2d2;
    width: 30px;
    height: 30px;
    margin-right: 5px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.onlineList .onlineItem .user_name {
    font-size: 12px;
    line-height: 1.3;
    white-space: nowrap;
}

.react-datepicker-wrapper {
    max-width: 100%;
}

.react-datepicker__input-container {
    overflow: hidden;
}

.react-datepicker-wrapper input {
    padding: 5.5px;
    animation-duration: 10ms;
    border: 1px;
    box-sizing: content-box;
    border-radius: 4px;
    border-style: solid;
    border-color: rgb(199, 199, 201);
    max-width: 130px;
    width: 100%;
}

.switcherWrap {
    display: flex;
    align-items: center;
}

.switch,
.switch .switcher {
    display: block;
    transition: all .3s ease;
}

.switch .switcher {
    background-color: #61677b;
    border-radius: 24px;
    content: "";
    height: 22px;
    left: -1px;
    position: absolute;
    top: -3px;
    width: 22px;
}

.switch {
    background-color: #444958;
    border-radius: 10px;
    cursor: pointer;
    height: 15px;
    margin-left: 15px;
    position: relative;
    width: 40px;
}

.switch.active {
    background-color: #2196f3;
    transition: all .2s ease;
}

.switch.active .switcher {
    background-color: #0064b5;
    left: 19px;
    transition: all .2s ease;
}

.loading {
    display: none;
    width: 300px;
    height: 40px;
    line-height: 40px;
    background-color: #ec830a;
    font-size: 24px;
    text-align: center;
    transition: all 0.5s ease;
    overflow: hidden;
    position: fixed;
    top: -50px;
    z-index: 100;
    left: 50%;
    margin-left: -150px;
    border-radius: 10px;
    color: #fff;
}

.loading.error {
    background: red;
    color: #fff;
    display: block;

}

.loading.active {
    top: 6px;
    display: block;
    transition: all 0.5s ease;
}

.tooltip {
    display: none;
    position: absolute;
    width: 190px;
    left: -85px;
    bottom: -31px;
    background-color: #111;
    padding: 5px;
    text-align: center;
    z-index: 1000;
}

.upload__image-wrapper .tooltip {
    bottom: -51px;
}

.upload__image-wrapper input {
    display: none;
}

.tooltip_wrap:hover .tooltip {
    display: block;
}

.fa-xmark.tw-h-5.tw-aspect-square {
    color: #161616;
}

.green_bd {
    background: #bfb;
}

.green {
    color: green;
}

.red {
    color: red;
}

.textCorr {
    background: #fff;
    color: #000;
    padding: 10px;
}


.textCorr a {
    color: #0d6efd;
}

.textCorr.title_obj,
.textCorr.title {
    height: 35px;
    background: transparent;
    color: #000;
    border: 1px solid rgba(14, 14, 14, 0.3);
    padding: 7px;
}

.wrapper.logged .textCorr.announce_obj,
.wrapper.logged .textCorr.announce {
    margin-top: 15px;
    height: 102px;
    background: transparent;
    color: #000;
    border: 1px solid rgba(14, 14, 14, 0.3);
}

.wrapper.logged .textCorr.content_obj,
.wrapper.logged .textCorr.content {
    height: 813px;
    word-break: normal;
    word-wrap: break-word;
    font-family: Helvetica Neue, sans-serif;
    font-size: 13px;
    padding: 103px 33px 0;
    background: transparent;
    color: #000;
    border: 1px solid rgba(14, 14, 14, 0.3);
    overflow-y: scroll;
    display: block;
    margin-top: 22px;

}

.sub-menu-1,
.sub-menu-2 {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.1s linear, visibility 0.1s linear;
}

.menu-item-1:hover .sub-menu-1 {
    visibility: visible;
    opacity: 1;
}

.menu-item-2:hover .sub-menu-2 {
    visibility: visible;
    opacity: 1;
}

#react-media-library-tabs-tabpane-library .control-item {
    width: 50%;
    padding: 10px 15px;
}

#react-media-library-tabs-tabpane-library .list-group-flush {
    display: flex;
    justify-content: space-between;
    flex-direction: unset;
}

#react-media-library-tabs-tabpane-library .list-group-data {
    width: 70%;
}

#react-media-library-tabs-tabpane-library .list-group-control {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 30%;

}

.MuiBox-root input {
    padding-left: 7px;
    padding-right: 4px;
}

.edit_line .cancel_line {
    width: 20px;
    height: 20px;
    background-position: 0 0;
    margin: 0 auto;
}

.edit_line .delete {
    color: #e51c23;
}

.dashBlock {
    border: solid 1px #000;
    width: 250px;
    background-color: #C5ECCF;

}

.dashTitle {
    background-color: #e51c23;
    color: #fff;
    text-align: center;
    padding: 10px;
}

.dashBtn {
    color: #fff;
    background-color: #9C27B0;
    padding: 5px 15px;
    font-size: 14px;
    margin: 10px auto;
    text-align: center;
    width: max-content;

}

.dashText {
    margin: 5px;
    margin-bottom: 10px;
    margin-top: 10px;
    text-align: center;
}
.dashRed{
    background: red;
}
 .form_data .byLang select ,   .form_data .byLang  .MuiInputBase-root  input{
    border: 1px solid blue;
    border-radius: 4px;  
}

 .form_data .byLang   .textArea  .MuiInputBase-root {
    border: 1px solid blue;
    border-radius: 4px;  
}
.form_data .byLang .sun-editor {
    border: 1px solid blue;
}
 .form_data .byLang .se-container{
    padding: 1px;
}

.scaleAnimation {
    animation: scaleAnimation 1s; /* Час анімації (1 секунда) */
}

@keyframes scaleAnimation {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}
.react-datepicker-popper{
    z-index: 100;
}
.react-datepicker{
    display: flex;
}
.countryList{
  display: flex;
  width: 340px;
  flex-wrap: wrap;
  position: absolute;
  right: -200px;
  z-index: 10000;
  background: #fff;
  box-shadow: 0px 0px 15px -10px;
  border-radius: 17px;
  padding: 10px;
  height: 70px;
  overflow: hidden;
}
.countryList:hover{
    height : auto;
    width: 400px;
}
.countryList li{
    display: block;
    width: auto;
    background:#ebebeb;
    padding: 2px 7px;
    margin: 5px;
    border-radius:10px;
    cursor: pointer;

}



