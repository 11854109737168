.privacyPolicy h1{
    margin: 20px 0;
    font-size: 35px;
}
.privacyPolicy h2{
    margin: 20px 0;
    color: #fff;
}
.privacyPolicyBloc1 p{
    margin: 10px 0;
}
.privacyPolicy h4{
    margin: 20px 0;
    font-size: 20px;
}
.privacyPolicy h5{
    margin: 20px 0;
    font-size: 20px;
}
.privacyPolicy p{
    margin: 10px 0;
    font-size: 15px;
}
.linkTelegram{
    color: #2060e9;;
}
.privacyPolicy li{
    margin: 5px 0;
}