.heager_emblema {
    width: 50px;
}

.logo span {
    margin-left: 5px;
    color: '#2060E9';
    font-size: 25px;
}

.login {
    font-size: 16px;
    text-transform: uppercase;
    color: #0064b5;
    margin-right: 20px;
    display: inline-block;
}

.reg {
    margin-right: 40px;
    border: solid 3px #0064b5;
    padding: 5px 25px;
    border-radius: 20px;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    padding: 20px 20px 10px 20px;
}

.header .logo {
    color: #0064b5;
    font-size: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#lang-select {
    color: #0064b5;
    background-color: transparent;
    border: 1px solid white;
    padding: 5px 10px;
    font-size: 16px;
    outline: none;
    border-radius: 4px;
}

@media (max-width: 768px) {
    .heager_emblema {
        width: 30px;
    }

    .header {
        padding: 20px 5px 10px 5px;
    }

    .logo {
        width: 10%;
    }

    .logo span {
        margin-left: 5px;
    }

    .registration {
        width: 75%;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .login {
        margin-right: 5px;
    }

    .reg {
        padding: 5px 15px;
        margin: 0;
    }

    #lang-select {
        padding: 0;
        margin-left: 5px;
    }
}