.contact-container {
  background-color: #fff;
  width: 100%;
  text-align: center;
  height: 100%;
  font-weight: bold;
  font-size: 14px;
  color: #424275;
  ;

}

.contact_content {
  display: flex;
  justify-content: space-between;

}

.contactTitle {
  margin-bottom: 20px;
}

.item a {
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #424275;
  ;

}

.item {
  margin: 5px 0;
}


.item img {
  margin-right: 45px;
}

.sendMail {
  width: 100%;
}

.contact_bloc1 {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact_bloc2 {
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.followUs {
  margin: 14px 0;
}

.addressTitle {
  margin-top: 14px;
}

.address {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.send_name,
.send_email,
.send_question,
.send_message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 20px;

}

.send_message {
  margin-bottom: 10px;
}

#nameInput,
#emailInput,
#question_send {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  width: 80%;
  background-color: white;

}

.contact-wrap {
  margin: 0 auto;
  width: 1000px;
  padding: 30px;
}

#message {
  border: 1px solid gray;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  background-color: white;
  height: 165px;
  resize: none;
}

.sendBtn {
  padding: 10px 40px;
  background-color: #2bae2b;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  font-size: 12px;
  margin-left: auto;
}

.sendBtn-container {
  text-align: right;
  width: 100%;
}

.noLink {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .contact-wrap {
    width: 100%;
  }

  .contact_content {
    flex-wrap: wrap;
  }

  .contact_bloc1,
  .contact_bloc2 {
    width: 100%;
  }

  #nameInput,
  #emailInput,
  #question_send,
  #message{
    width: 100%;
  }
}